<template>
    <div>
        <div class="text-center p-t-10">
          <div v-for="tag in tagList" :key="tag.key"  class="p-l-20 p-r-20"
            @click="handleChangeTag(tag.key)" :class="currentTag=== tag.key?'workplatform-radio-button-active':'workplatform-radio-button'">
            {{tag.name}}</div>
        </div>

        <!-- <Divider><span class="divider-text">创建{{tagName}}</span></Divider> -->
         <div class="default-content">
          <p style="font-size: large;">{{tagName}}详细信息请在右侧查看</p>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      createComponent: '',
      currentTag: 1,
      tagName: '',
      tagList: [
        { key: 1, name: '合同' },
        { key: 5, name: '结算单' },
        { key: 6, name: '结算单变更' },
        { key: 2, name: '确认单' },
        { key: 3, name: '合同变更' },
        { key: 4, name: '合同开票' }
      ]
    }
  },
  created () {
    this.handleChangeTag(this.currentTag)
  },
  methods: {
    handleChangeTag (tag) {
      this.currentTag = tag
      this.tagName = this.tagList.find(x => x.key === tag).name
      let pageType = ''

      switch (tag) {
        case 1:
          pageType = 'contract'
          break
        case 2:
          pageType = 'agreement'
          break
        case 3:
          pageType = 'contractChange'
          break
        case 4:
          pageType = 'invoice'
          break
        case 5:
          pageType = 'statement'
          break
        case 6:
          pageType = 'statementChange'
          break
        default:
          break
      }

      this.$emit('on-change', pageType, this.tagName)
    },
    handleCreate (baseId) {
      this.$emit('on-create', baseId)
    }
  }
}
</script>
